import React from "react";
import brand from "../../Resources/Images/logo_negative.webp";

const Footer =()=>{
    return (
        <React.Fragment>
            <div className="w-full h-60 md:h-32 flex flex-col md:flex-row items-center gap-6 py-10 bg-gradient-to-b from-sky-800 to-sky-900">
                <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                    <img width={148} height={50} class="w-[148px] h-[50px]" src={brand} alt="Imagen del footer"/>
                </div>
                <div className="w-full md:w-1/2 text-center md:text-left text-white">
                    <p>
                        311 482 68 33
                    </p>
                    <p>
                        Calle 138 d carrera 152. 
                    </p>
                    <p>
                        Bogotá, Colombia.
                    </p>
                </div>
            </div>
            <div className="bg-sky-950 text-right p-5 ">
                <p className="text-white text-xs">
                    Desarrollado por <a className="font-bold" href="https://brainmeg.com/" target="blank">Brain Meg</a>
                </p>
            </div>
        </React.Fragment>
    )
}
export default Footer;