import React from "react";

const UIButton = ({
    disabled,
    primary,
    whatsapp,
    whatsappNumber,
    className,
    children,
    action,
}) => (
    <React.Fragment>
        <button
            disabled={disabled}
            className={`
                outline-none
                ${className}
                ${disabled && 'bg-slate-500'}
                ${primary && 'bg-sky-800 rounded-full px-5 py-1 text-white font-bold'}
                ${whatsapp && 'fixed right-2 bottom-1/4 w-[40px] md:w-[70px] h-[40px] md:h-[70px] z-10'}
            `}
            onClick={() => {
                if (whatsappNumber) {
                    window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, '_blank');
                } else if (action) {
                    action();
                }
            }}
        >
            {children}
        </button>
    </React.Fragment>
);

export default UIButton;
