import React from "react";
import brand from "../../Resources/Images/logo_positive.webp";

const UIBrand =()=>{
    return (
        <React.Fragment>
            <picture>
                <source srcset={brand} type="image/webp" sizes="(max-width: 600px) 100vw, 50vw" />
                <img className="w-full block lg:hidden" src={brand} width={312} height={108}  alt="Vidrios Aluminios Jaime" sizes="(max-width: 600px) 100vw, 50vw"></img>
                <img className="w-full hidden lg:block" src={brand} width={312} height={108} alt="Vidrios Aluminios Jaime" sizes="(max-width: 600px) 100vw, 50vw"></img>
            </picture>
        </React.Fragment>
    )
}
export default UIBrand;