import React, {lazy, Suspense} from 'react';
import { createBrowserRouter } from 'react-router-dom';
import HomeOut from '../Components/Pages/Home/HomeOut';

const Contact = lazy(()=>import('../Components/Pages/Contact/Contact'));

export const Routes = createBrowserRouter([
    {
        path:'/',
        element:<Suspense fallback={<div>...</div>}><HomeOut/></Suspense>
    },
    {
        path:'/contacto',
        element: <Suspense fallback={<div>...</div>}><Contact/></Suspense>
    },
]);