import React, { useState, useEffect } from "react";
import UIBrand from "../Atoms/UIBrand";
import UIButton from "../Atoms/UIButton";
import brand from "../../Resources/Images/logo_positive.webp";
import arrowDown from "../../Resources/Images/arrow_down.webp";
import arrowUp from "../../Resources/Images/arrow_up.webp";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setMenuOpen(false);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (menuOpen && window.innerWidth >= 768) {
            setMenuOpen(false);
        }
    }, [menuOpen]);

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <React.Fragment>
            <header className="w-full bg-white bg-opacity-80 p-4 flex justify-between items-center fixed z-10" id="header">
                <div className="w-full flex justify-between items-center">
                    <div className="w-28 md:w-52">
                        <UIBrand />
                    </div>
                    <nav className="hidden md:flex items-center">
                        <a href="#inicio" className="font-bold text-slate-800 mr-8 hover:text-sky-950">Inicio</a>                        
                        <div className="relative inline-block">
                            <button onClick={toggleDropdown} className="text-slate-800 py-2 flex-row">
                                <div className="inline-block font-bold">
                                    Servicios 
                                </div>
                                <div className="inline-block pl-2">
                                    <img src={isOpen ? arrowUp : arrowDown} alt="desplegar menú"/>
                                </div>
                            </button>
                            {isOpen && (
                                <div className="absolute top-10 right-0 bg-white border rounded-2xl border-gray-300 shadow-md px-2">
                                    <ul className="p-2">
                                        <li className="my-2">
                                            <a className="hover:text-sky-950" href="#divisiones">
                                                Divisiones
                                            </a>
                                        </li>
                                        <li className="my-2">
                                            <a className="hover:text-sky-950" href="#escaleras">
                                                Escaleras
                                            </a>
                                        </li>
                                        <li className="my-2">
                                            <a className="hover:text-sky-950" href="#fachadas">
                                                Fachadas
                                            </a>
                                        </li>
                                        <li className="my-2">
                                            <a className="hover:text-sky-950" href="#ventanas">
                                                Ventanas
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <a href="#contacto" className="font-bold text-slate-800 mx-4 hover:text-sky-950">Contacto</a>
                        <UIButton 
                            primary
                            whatsappNumber="3114826833"
                            children={'¿Qué necesitas?'}
                        />
                    </nav>
                </div>
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-slate-800 text-[28px]">
                        ☰
                    </button>
                </div>
                {menuOpen && (
                    <div
                        className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-60 flex justify-end items-center"
                        onClick={toggleMenu}
                    >
                        <div className="bg-white h-full w-3/4 px-4 pt-20" onClick={(e) => e.stopPropagation()}>
                            <div className="flex-row h-[400px]">
                                <div className="">
                                    <nav>
                                        <a onClick={toggleMenu} href="#inicio" className="block font-bold text-black mb-4">
                                            Inicio
                                        </a>
                                        <a onClick={toggleMenu} className="block font-bold mb-4 hover:text-sky-950" href="#divisiones">
                                            Divisiones
                                        </a>
                                        <a onClick={toggleMenu} className="block font-bold mb-4 hover:text-sky-950" href="#escaleras">
                                            Escaleras
                                        </a>
                                        <a onClick={toggleMenu} className="block font-bold mb-4 hover:text-sky-950" href="#fachadas">
                                            Fachadas
                                        </a>
                                        <a onClick={toggleMenu} className="block font-bold mb-4 hover:text-sky-950" href="#ventanas">
                                            Ventanas
                                        </a>
                                        <a onClick={toggleMenu} className="block font-bold text-black mb-10" href="#contacto">
                                            Contacto
                                        </a>
                                        <UIButton
                                            primary
                                            whatsappNumber="3114826833"
                                            children={'¿Qué necesitas?'}
                                        />
                                    </nav>                                    
                                </div>
                                <div className="p-20 mt-[10%]">
                                    <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                                        <img width={100} height={50} class="w-32 h-fit" src={brand} alt="Canales de contactos"/>
                                    </div>
                                    <div className="w-full md:w-1/2 text-center md:text-left text-black mt-5">
                                        <p>
                                            311 482 68 33
                                        </p>
                                        <p>
                                            Calle 138 d carrera 152.
                                        </p>
                                        <p>
                                            Bogotá, Colombia.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <button onClick={toggleMenu} className="absolute top-4 right-4 text-black">
                                ✕
                            </button>
                        </div>
                    </div>
                )}
            </header>
        </React.Fragment>
    )
}

export default Header;
