import React from "react";
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { Routes } from './Routes/Routes';
import Header from "./Components/Organisms/Header";
import Footer from "./Components/Organisms/Footer";

function App() {
  return (
    <div className="App">
      <Header />
        <RouterProvider router={Routes} />
      <Footer />
    </div>
  );
}

export default App;