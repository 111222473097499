import React, { lazy, Suspense } from "react";
import UIButton from "../../Atoms/UIButton";
import whatsapp from "../../../Resources/Images/whatsapp.webp";
import goTop from "../../../Resources/Images/button_gotop.webp";

// Componentes lazy-loaded
const Contact = lazy(() => import("../Contact/Contact"));
const Masonry = lazy(() => import("../../Molecules/Gallery/Masonry"));

// Función para desplazarse al inicio de la página
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const HomeOut = () => {
    return (
        <React.Fragment>
            <div id="inicio" className="w-screen min-[320px]:h-[840px] min-[425px]:h-[1020px] min-[530px]:h-[1080px] min-[625px]:h-[1180px] sm:h-[1280px] md:h-[600px] lg:h-[560px] xl:h-[700px] pt-32 px-0 bg-principal bg-cover bg-no-repeat bg-bottom">
                <div className="w-full lg:w-10/12 xl:w-11/12 m-auto h-full flex flex-col md:flex-row bg-banner-hero bg-contain  bg-no-repeat bg-center">
                    <div className="w-full h-full md:w-1/2">
                        <div className="w-full lg:w-3/4 xl:w-1/3 2xl:w-1/2 mx-auto pt-1 md:px-10 xl:px-0">
                            <p className="text-4xl font-bold text-sky-800">
                                Aluminio
                            </p>
                            <p className="text-slate-800 leading-tight">
                                <strong>Elegancia y durabilidad,</strong> resistentes a la corrosión y fácil mantenimiento, estilos funcionales modernos y sofisticados
                            </p>
                        </div>
                    </div>
                    <div className="w-full h-full md:w-1/2 flex items-end justify-center">
                        <div className="w-full lg:w-3/4 xl:w-1/3 2xl:w-1/2 mx-auto pb-10 md:px-10 xl:px-0">
                            <p className="text-4xl font-bold text-sky-800">
                                Acero
                            </p>
                            <p className="text-slate-800 leading-tight">
                                <strong>Robustés y confiabilidad,</strong> larga vida útil para soluciones variadas de ingeniería
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Suspense fallback={<div>Cargando Galería...</div>}>
                    <Masonry />
                </Suspense>
            </div>
            <div id="contacto" className="py-4 mt-10 2xl:w-10/12 m-auto">
                <Suspense fallback={<div>Cargando Contacto...</div>}>
                    <Contact />
                </Suspense>
            </div>
            <UIButton
                whatsapp
                whatsappNumber="3114826833"
                children={<img src={whatsapp} alt="whatsapp" loading="lazy" />}
            />
            <button onClick={scrollToTop}>
                <img src={goTop} loading="lazy" className="fixed right-3 bottom-[15%] sm:bottom-[10%] w-[40px] md:w-[70px] h-[40px] md:h-[70px] z-10" alt="Subir" />
            </button>
        </React.Fragment>
    )
}

export default HomeOut;
